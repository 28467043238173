<template>

    <!-- Main content -->
    <section class="content">
        <div class="btn-box">
            <el-form :inline="true">
                <el-form-item label="用户user_id：">
                    <el-input v-model="unionid" placeholder="用户user_id"></el-input>
                </el-form-item>
                <el-form-item label="提现开始时间：">
                    <el-date-picker v-model="start_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="提现开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="提现结束时间：">
                    <el-date-picker v-model="end_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="提现结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="提现平台：">
                    <el-select v-model="platform" placeholder="请选择">
                        <el-option v-for="item in platform_list" :key="item.type" :label="item.name" :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="status" placeholder="请选择">
                        <el-option v-for="item in status_list" :key="item.type" :label="item.name" :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="seach(1)">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table v-loading="loading" :data="withdrawList" border>
            <el-table-column fixed prop="id" label="编号" width="100">
            </el-table-column>
            <el-table-column label="user_id（user_key）" width="200" fixed>
                <template slot-scope="scope">
                    {{ scope.row.user_id }}({{ scope.row.user_key }})
                </template>
            </el-table-column>
            <el-table-column prop="amount" label="金额" width="100"></el-table-column>
            <el-table-column label="提现时间">
                <template slot-scope="scope">
                    {{ scope.row.create_time | dateTime }}
                </template>
            </el-table-column>
            <el-table-column prop="account_no" label="提现账号" width="250"></el-table-column>
            <el-table-column prop="account_name" label="提现账户名称"></el-table-column>
            <el-table-column label="提现平台" width="100">
                <template slot-scope="scope">
                    {{ scope.row.platform | platform }}
                </template>
            </el-table-column>
            <el-table-column prop="audit_reason" label="审核原因描述" width="300"></el-table-column>
            <el-table-column label="审核时间" width="160">
                <template slot-scope="scope">
                    {{ scope.row.audit_time | dateTime }}
                </template>
            </el-table-column>
            <el-table-column prop="audit_result_remark" label="原因" width="300"></el-table-column>
            <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                    <span
                        :class="scope.row.status == 1 ? 'green' : scope.row.status == 2 || scope.row.status == 6 ? 'red' : scope.row.status == 5 ? 'warn' : ''">
                        {{ scope.row.status | status }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="150">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="auditDialog(scope.row.id, 1)"
                        v-if="scope.row.status === '5'">通过</el-button>
                    <el-button type="danger" size="mini" @click="auditDialog(scope.row.id, 2)"
                        v-if="scope.row.status === '5'">拒绝</el-button>
                    <el-button type="primary" size="mini" @click="openDetailDialog(scope.row)">收入明细</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total"
            @size-change="sizeChange" @current-change="currentChange">
        </el-pagination>
        <el-dialog :visible.sync="detailDialogShow" :title="`收入明细${editRow.user_id}(${editRow.user_key})`" width="80%">
            <el-table v-loading="loading" :data="incomeList" height="50vh" border>
                <el-table-column fixed prop="id" label="编号" width="100">
                </el-table-column>
                <el-table-column prop="amount" label="金额"></el-table-column>
                <el-table-column label="时间">
                    <template slot-scope="scope">
                        {{ scope.row.create_time | dateTime }}
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
                <el-table-column label="类型" width="100px">
                    <template slot-scope="scope">
                        {{ scope.row.type | type }}
                    </template>
                </el-table-column>
                <el-table-column label="入账后余额" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type == 1" class="tip">
                            {{ Number(Number(scope.row.balance) + Number(scope.row.amount)).toFixed(2) }}
                        </div>
                        <div v-else>
                            {{ scope.row.balance }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="入账后积分" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type == 2" class="tip">
                            {{ Number(Number(scope.row.score) + Number(scope.row.amount)).toFixed(2) }}
                        </div>
                        <div v-else>
                            {{ scope.row.score }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="入账后免单点" width="120">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type == 3" class="tip">
                            {{ Number(Number(scope.row.free_order_point) + Number(scope.row.amount)).toFixed(2) }}
                        </div>
                        <div v-else>
                            {{ scope.row.free_order_point }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="来源" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.source_type | source }}
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination style="margin-top: 20px;" background layout="total, sizes,prev, pager, next,jumper" :total="dtotal"
                @size-change="dsizeChange" @current-change="dcurrentChange">
            </el-pagination>
        </el-dialog>
    </section>
    <!-- /.content -->
</template>

<script>
import * as api from '@/config/api'
let common = JSON.parse(window.localStorage.getItem('common'))
export default {
    name: 'withdraw',
    components: {},
    data() {
        return {
            withdrawList: [],
            platform_list: [...[{
                type: '',
                name: '全部'
            }], ...common.withdraw_platform_list],
            status_list: [...[{
                type: '',
                name: '全部'
            }], ...common.withdraw_status_list],
            total: 0,
            page: 1,
            pagesize: 10,

            unionid: '',
            status: '',
            platform: '',
            start_time: '',
            end_time: '',
            loading: true,
            detailDialogShow: false,
            editRow: {},
            incomeList: [],
            dtotal: 0,
            dpage: 1,
            dpagesize: 10,
            dloading: false
        }
    },
    watch: {},
    filters: {
        status(val) {
            let name = ''
            if (JSON.stringify(common.withdraw_status_list).indexOf(val) != -1) {
                common.withdraw_status_list.map((a, index) => {
                    if (val === a.type) {
                        name = a.name
                    }
                })
            }
            return name
        },
        source(val) {
            let name = ''
            if (JSON.stringify(common.source_type_list).indexOf(val) != -1) {
                common.source_type_list.map((a, index) => {
                    if (val === a.type) {
                        name = a.name
                    }
                })
            }
            return name
        },
        platform(val) {
            let name = ''
            if (JSON.stringify(common.withdraw_platform_list).indexOf(val) != -1) {
                common.withdraw_platform_list.map((a, index) => {
                    if (val === a.type) {
                        name = a.name
                    }
                })
            }
            return name
        },
        type(val) {
            let name = ''
            if (JSON.stringify(common.bill_type_list).indexOf(val) != -1) {
                common.bill_type_list.map((a, index) => {
                    if (val === a.type) {
                        name = a.name
                    }
                })
            }
            return name
        },
        dateTime(val) {
            let day = new Date(Number(val) * 1000)
            if (Number(val) === 0) {
                return ''
            }
            return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate() < 10 ? '0' + day.getDate() : day.getDate()}` + ' ' + `${day.getHours() < 10 ? '0' + day.getHours() : day.getHours()}` + ':' + `${day.getMinutes() < 10 ? '0' + day.getMinutes() : day.getMinutes()}` + ':' + `${day.getSeconds() < 10 ? '0' + day.getSeconds() : day.getSeconds()}`
        }
    },
    mounted() {
        this.getWithdraw()
    },
    methods: {
        openDetailDialog(row) {
            this.editRow = row
            this.detailDialogShow = true
            this.dpage = 1
            this.getIncome(this.dpage, this.dpagesize)
        },
        getWithdraw(page, size) {
            this.loading = true
            api.getWithdraw({
                user_id: this.unionid,
                start_time: this.start_time,
                end_time: this.end_time,
                status: this.status,
                platform: this.platform,
                page: page ? page : this.page,
                pagesize: size ? size : this.pagesize
            }, res => {
                this.withdrawList = res.data.list
                this.total = Number(res.data.count)
                this.loading = false
            })
        },
        getIncome(page, size) {
            this.dloading = true
            api.getIncome({
                user_id: this.editRow.user_id,
                type: 1,
                page: page ? page : this.dpage,
                pagesize: size ? size : this.dpagesize
            }, res => {
                this.incomeList = res.data.list
                this.dtotal = Number(res.data.count)
                this.dloading = false
            })
        },
        sizeChange(val) {
            this.pagesize = val
            this.getWithdraw()
        },
        dsizeChange(val) {
            this.dpagesize = val
            this.getIncome()
        },
        dcurrentChange(val) {
            this.dpage = val
            this.getIncome()
        },
        currentChange(val) {
            this.page = val
            this.getWithdraw()
        },
        seach(page, size) {
            this.getWithdraw(page, size)
        },
        auditDialog(id, status) {
            this.$confirm(status === 1 ? '请确定是否通过' : '请确定是否拒绝', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (status === 2) {
                    this.$prompt('请输入拒绝理由', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        inputPattern: /^\S{1,100}$/,
                        inputErrorMessage: '拒绝原因不能为空或超过100个字'
                    }).then((res) => {
                        this.audit(id, status, res.value)
                    }).catch(() => { });
                } else {
                    this.audit(id, status)
                }
            }).catch(() => { })
        },
        audit(id, status, value) {
            api.auditWithdraw({
                id: id,
                status: status,
                audit_result_remark: value
            }, res => {
                this.$message({
                    type: 'success',
                    message: res.msg
                });
                this.getWithdraw()
            })
        }
    }
}
</script>
<style scoped>
.tip {
    color: #ff5050;
}

.cell {
    text-align: center;
}

.btn-box {
    text-align: left;
    margin-bottom: 20px;
}

img {
    width: 150px;
}

.red {
    color: #F56C6C
}

.blue {
    color: #409EFF
}

.green {
    color: #67C23A
}

.warn {
    color: #E6A23C
}
</style>